<template>
	<div class="menu_box">
		<div class="btn" @click="gorouter(index)" :class="menuindex==index&&menuindex==0?'active1':menuindex==index&&menuindex==1?'active2':menuindex==index&&menuindex==2?'active3':menuindex==index&&menuindex==3?'active4':menuindex==index&&menuindex==4?'active5':''" v-for="(item,index) in menu" :key="index">
			{{item}}
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				menu:['社情民意大数据','提案大数据','委员大数据','会议/活动大数据','承办大数据'],
				menuindex:0
			}
		},
		created() {
			console.log(this.$router)
			this.$router.onReady(() => {
			    if(this.$route.fullPath=='/tiandata'){
					this.menuindex=1
					return false
				} 
				if(this.$route.fullPath=='/weiyuandata'){
					this.menuindex=2
					return false
				} 
				if(this.$route.fullPath=='/Meeting'){
					this.menuindex=3
					return false
				} 
				if(this.$route.fullPath=='/cbdsj'){
					this.menuindex=4
					return false
				} 
			})
			
			
		},
		methods:{
			gorouter(index){
				this.menuindex = index
				switch(index){
					case 0:
						this.$router.push('/Home')
						break;
					case 1:
						this.$router.push('/tiandata')
						break;
					case 2:
						this.$router.push('/weiyuandata')
						break;
					case 3:
						this.$router.push('/Meeting')
						break;
					case 4:
						this.$router.push('/cbdsj')
						break;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.menu_box{
		width: 100vw;
		height: 10vh;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 6%;
		.btn{
			padding: 10px 0;
			width: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			cursor: pointer;
			color: #fff;
		}
		.btn:nth-child(1){
			border: 1px solid rgb(11,90,245);
		}
		.btn:nth-child(2){
			border: 1px solid rgb(28,123,59);
		}
		.btn:nth-child(3){
			border: 1px solid rgb(236,175,13);
		}
		.btn:nth-child(4){
			border: 1px solid rgb(144,14,219);
		}
		.btn:nth-child(5){
			border: 1px solid deepskyblue;
		}
		.active1{
			background-color: rgb(11,90,245);
			color: #fff;
		}
		.active2{
			background-color: rgb(28,123,59);
			color: #fff;
		}
		.active3{
			background-color: rgb(236,175,13);
			color: #fff;
		}
		.active4{
			background-color: rgb(144,14,219);
			color: #fff;
		}
		.active5{
			background-color: deepskyblue;
			color: #fff;
		}
	}
</style>