import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/tiandata',
    name: 'tiandata',
    component: () => import('../views/tiandata.vue')
  },
  {
    path: '/weiyuandata',
    name: 'weiyuandata',
    component: () => import('../views/weiyuandata.vue')
  },
  {
    path: '/Meeting',
    name: 'Meeting',
    component: () => import('../views/Meeting.vue')
  },
  {
    path: '/cbdsj',
    name: 'cbdsj',
    component: () => import('../views/cbdsj.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
	console.log(store.state)
	next()
})

export default router
