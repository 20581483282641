<template>
  <div class="header">  
	<div style="font-weight: bold;display: flex;align-items: center;"><img style="width: 0.3rem;margin-right: 0.1rem;" src="../assets/logo.png" alt="">西宁市城北区数字政协管理平台</div>
	<div class="user">
		<div class="btn" @click="goother">
			进入后台
		</div>
		<div>
			admin
		</div>
		<div style="color: rgb(92,171,250);margin-left: 0.1rem;">
			管理员
		</div>
	</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  methods:{
	  goother(){
		  window.location.href='https://coti.zhihuiqinghai.com/zxpc'
	  }
  }
  
}
</script>

<style scoped lang="less">
	.header{
		height: 7vh;
		width: 100vw;
		color: #fff;
		padding: 0 0.2rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 0 5px #c0c0c0;
		.user{
			font-size: 0.12rem !important;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn{
				padding: 5px 0.1rem;
				background: rgb(230,241,252);
				border: 1px solid rgb(92,171,250);
				color: rgb(92,171,250);
				cursor: pointer;
				border-radius: 5px;
				margin-right: 0.1rem;
			}
		}
	}
</style>
