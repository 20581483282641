<template>
  <div id="app">
    <Header v-if="$route.name!='login'"/>
	<headermenu v-if="$route.name!='login'"/>
	<div class="box_main" v-if="$route.name!='login'">
		<router-view/>
	</div>
    <div class="videoContainer">
          <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="" muted="" loop="">
            <source src="./assets/video_bg.mp4" type="video/mp4">
          </video>
    </div>
	<router-view v-if="$route.name=='login'"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import headermenu from '@/components/headermenu.vue'
export default {
  name: 'Home',
  components: {
    Header,
	headermenu
  }
}
</script>

<style lang="less">
	*{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		
	}
	body{
		background: #fff;
		font-size: 0.14rem;
	}
	.box_main{
		
		width: 100vw;
		padding: 0 0.1rem;
	}
	.videoContainer{
	  position: fixed;
	  width: 100vw;
	  height: 100vh;
	  overflow: hidden;
	  z-index: -100;
	  top: 0;
	  left: 0;
	}
	
	.videoContainer:before{
	  content: "";
	  position: absolute;
	  width: 100%;
	  height: 100%;
	  display: block;
	  z-index: -1;
	  top: 0;
	  left: 0;
	  background: rgba(25,29,34,.65);
	}
	
	.wirthbackgour{
		color: #fff !important;
	}
	/deep/ .block{
		input{
			background: none !important;
		}
	}
	.selectmouth {
		display: flex;
		align-items: center;
		width: 0.8rem;
	
		div {
			flex: 1;
			text-align: center;
			padding: 3px 0;
			cursor: pointer;
		}
	
		div:nth-child(1) {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border: 1px solid rgb(11, 90, 245);
			color: rgb(11, 90, 245);
		}
	
		div:nth-last-child(1) {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-top: 1px solid #c0c0c0;
			border-bottom: 1px solid #c0c0c0;
			border-right: 1px solid #c0c0c0;
		}
	}
	.yearactive{
		div:nth-child(1) {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-top: 1px solid #c0c0c0;
			border-bottom: 1px solid #c0c0c0;
			border-left: 1px solid #c0c0c0;
			color: #fff;
		}
				
		div:nth-last-child(1) {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border: 1px solid rgb(11, 90, 245);
			color: rgb(11, 90, 245);
		}
	}
</style>
